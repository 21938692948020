<template>
  <div class="flex-1 test-form">
    <h1>Анкета</h1>
    <div class="test-form__content align-item-center flex flex-1">
      <div class="test-form__registration">
        <form id="testForm" action="#" @submit="clickOnBtn" novalidate>
          <custom-input
            v-model="form.last_name"
            label="Фамилия"
            name="last_name"
            req
          />
          <custom-input
            v-model="form.first_name"
            label="Имя"
            name="first_name"
            req
          />
          <custom-input
            v-model="form.patronymic"
            label="Отчество"
            name="patronymic"
            req
          />
          <custom-input
            v-model="form.age"
            label="Возраст"
            name="age"
            type="number"
            maska="###"
            req
            :maxValue="100"
            :minValue="1"
          />
          <div class="flex justify-content-center test-form__radios">
            <custom-radio
              v-model="form.sex"
              id="radio-male"
              label="Мужчина"
              name="sex"
              value="male"
            />
            <custom-radio
              v-model="form.sex"
              id="radio-female"
              label="Женщина"
              name="sex"
              value="female"
            />
          </div>
          <custom-select-2
            v-model="form.profession_id"
            label="Проф. деятельность"
            name="profession_id"
            :options="professionOptions"
          />
          <custom-select-2
            v-model="form.industry_id"
            label="Отрасль"
            name="industry_id"
            :options="industriesOptions"
          />
          <custom-select-2
            v-model="form.position_id"
            label="Уровень должности"
            name="position_id"
            :options="positionOptions"
          />
          <city-input
              :city="form.city"
              @changeCity="(cityId) => (form.city_id = cityId)"
          />
          <custom-checkbox
            v-model="approval"
            class="test-form__personal"
            label="Согласие на обработку персональных данных"
            name="approval"
            req
          />
        </form>
      </div>
      <div class="test-form__instruction">
        <div class="test-form__instruction-title">
          Все поля обязательны для заполнения
        </div>
        <br />
        <div class="test-form__instruction-text">
          Заполняя данную анкету, вы выражаете свое согласие на сбор, обработку,
          использование, запись, систематизацию, накопление, хранение, уточнение
          (обновление, изменение), извлечение, обезличивание, блокирование,
          удаление своих персональных данных, указанных в настоящей анкете, в
          целях обработки статистической информации.
        </div>
      </div>
    </div>

    <div class="test-form__btn flex justify-content-between">
      <button class="btn btn-w" @click="backToInstruction">Назад</button>
      <button
        form="testForm"
        type="submit"
        :disabled="!approval"
        class="btn btn-accent"
      >
        Начать тест
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomRadio from "@/components/Forms/Fields/CustomRadio.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import { computed, onBeforeMount, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import useErrors from "@/mixins/useErrors";
import standartError from "@/mixins/standartError";
import { notify } from "@kyvg/vue3-notification";
import useProfileForm from "@/mixins/useProfileForm";
import CityInput from "@/components/Forms/Fields/CityInput.vue";
export default {
  components: {CityInput, CustomInput, CustomRadio, CustomSelect2, CustomCheckbox },
  setup(props, context) {
    const store = useStore(),
      route = useRoute(),
      { setErrors, clearCustomErrors } = useErrors(),
      { setForm } = useProfileForm();
    const { cityAjax } = useAjaxSelect();
    let professionOptions = ref([]),
        industriesOptions =  ref([]),
      positionOptions = ref([]);
    const positionList = computed(() => store.state.test.positionList),
      professionList = computed(() => store.state.test.professionList),
      industriesList =  computed(() => store.state.test.industriesList),
      profile = computed(() => store.state.profile.profile);
    let approval = ref(false);
    const form = reactive({
      last_name: null,
      first_name: null,
      patronymic: null,
      age: null,
      sex: null,
      profession_id: null,
      industry_id: null,
      position_id: null,
      city: null,
      city_id: null,
    });

    onBeforeMount(() => {
      store.dispatch("test/getPositionList").then(() => {
        positionOptions.value = positionList.value.map((position) => {
          return { id: position.id, text: position.text };
        });
      });
      store.dispatch("test/getProfessionList").then(() => {
        professionOptions.value = professionList.value.map((profession) => {
          return { id: profession.id, text: profession.text };
        });
      });
      store.dispatch("test/getIndustriesList").then(() => {
          industriesOptions.value = industriesList.value.map((industry) => {
              return { id: industry.id, text: industry.text };
          });
      });
      store.dispatch("profile/getProfile").then(() => {
        setForm(profile.value, form);
      });
    });

    const clickOnBtn = (event) => {
      event.preventDefault();
      if (
        !approval.value ||
        !form.last_name ||
        !form.first_name ||
        !form.patronymic ||
        !form.age ||
        !form.sex ||
        !form.profession_id ||
        !form.position_id ||
        !form.city_id ||
        !form.industry_id
      ) {
        notify({
          title: "Не все поля заполнены",
          type: "error",
        });
        let errors = {};
        Object.entries(form).forEach(([key, value]) => {
          if (key !== "city" && key !== "image" && !value) {
            errors[key] = ["Поле не заполнено"];
          }
        });
        setErrorsField(errors);
        return;
      }
      if (form.age <= 0 || form.age > 100) {
        notify({
          title: "Возраст должен быть числом от 1 до 100",
          type: "error",
        });
        setErrorsField({ age: ["Возраст должен быть числом от 1 до 100"] });
        return;
      }

      store
        .dispatch("test/sendTestForm", { code: route.params.code, form })
        .then(() => {
          context.emit("clickOnBtn");
        })
        .catch(standartError(setErrors, clearCustomErrors));
    };
    const backToInstruction = () => {
      context.emit("backToInstruction");
    };
    const setErrorsField = (errors) => {
      setErrors(errors);
      clearCustomErrors();
    };
    return {
      professionOptions,
      industriesOptions,
      positionOptions,
      form,
      clickOnBtn,
      backToInstruction,
      approval,
      positionList,
      professionList,
      profile,
      cityAjax,
    };
  },
};
</script>

<style lang="scss" scoped>
.test-form {
  text-align: left;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 20px 70px;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;
  position: relative;
  border: 2px solid transparent;

  &__city {
    margin: 20px -5px;
    padding-left: 15px;
    font-weight: 400;
    word-break: keep-all;

    span {
      font-weight: 300;
    }
  }

  // .test-form__registration

  &__registration {
    flex: 1 1 50%;
  }

  // .test-form__radios

  &__radios {
    .form-group:not(:last-child) {
      margin-right: 1rem;
    }
  }

  // .test-form__instruction

  &__instruction {
    flex: 0 1 50%;
    margin-left: 30px;
    margin-top: -5%;
  }

  // .test-form__instruction-title

  &__instruction-title {
    font-weight: 400;
  }

  &__btn {
    padding: 3px 5px;
    .btn {
      padding: 11px 25px;
    }
  }

  &__radios {
    margin-top: 20px;
  }

  &__personal {
    margin-top: 20px;
    justify-content: center;
  }

  @media (max-width: 1500px) {
    &__registration {
      flex: 1 1 65%;
    }
    &__instruction {
      flex: 0 1 35%;
    }
  }

  @media (max-width: 1507px) {
    &__content {
      flex-direction: column-reverse;
    }
    &__instruction {
      flex: 1;
      margin-left: 0;
      padding-top: 10px;
      margin-top: 0;
    }
    &__registration {
      flex: 1;
      width: 100%;
      padding: 20px 0;
    }
  }
  @media (max-width: 974px) {
    &__registartion {
      padding: 0 0 10px 0;
    }
    &__radios {
      margin-top: 25px;
    }
    padding: 20px;
  }
}

.error-form {
  border: 2px solid red;
}
</style>
