<template>
  <div
    v-if="questionGroup"
    class="
      flex flex-column
      justify-content-center
      list-questions
      align-item-center
      w-full
    "
  >
    <img
      v-if="questionGroup.image"
      class="list-questions__image"
      :src="`${imageUrl}/${questionGroup.image}`"
      alt="Картинка"
    />
    <p class="list-questions__description">{{ questionGroup.text }}</p>
    <div v-if="questionGroup.questions" class="w-full">
      <div
        v-for="question in questionGroup.questions"
        :key="question.id"
        class="list-questions__answer"
      >
        <p v-if="question.text" class="list-questions__text">
          {{ question.text }}
        </p>
        <div class="list-questions__btns flex flex-column w-full">
          <div
            v-for="answer in question.answers"
            :key="answer.id"
            class="list-questions__radio-wrap flex"
          >
            <label :for="`answer_${question.id}_${answer.id}`">{{
              answer.text
            }}</label>
            <input
              :id="`answer_${question.id}_${answer.id}`"
              :name="`answer_${question.id}`"
              type="radio"
              tabindex="1"
              @change="setAnswer"
              :value="answer.value"
              :data-answer="answer.id"
              :data-question="question.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import useAnswers from "@/mixins/useAnswers";
export default {
  name: "list-question-group",
  props: {
    questionGroup: {
      type: Object,
    },
  },
  inject: ["imageUrl"],
  setup(props, context) {
    const group = toRef(props, "questionGroup");
    const { setAnswer, clearAnswers } = useAnswers(context);

    watch(group, () => {
      clearAnswers();
    });

    return {
      setAnswer,
    };
  },
};
</script>

<style lang="scss" scoped>
.list-questions {
  &__image {
    display: block;
    max-width: 600px;
    max-height: 300px;
    margin-bottom: 15px;
    border-radius: 4px;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
  }

  &__description {
    font-size: 20px;
  }

  &__answer {
    border: 1px solid #ececee;
    border-radius: 4px;
    padding: 25px 75px;
    width: 60%;
    margin: 20px auto;
  }

  &__text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    margin: 0 0 15px;
    white-space: break-spaces;
    word-break: break-word;
  }

  &__radio-wrap {
    flex: 1;
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid var(--cream-color);

    label {
      display: block;
      text-align: left;
      flex: 1 0;
      word-break: keep-all;
      font-size: 18px;
      padding: 0 20px 0 5px;
      white-space: break-spaces;
    }

    input {
      width: 24px;
      height: 24px;
      display: block;
      background-color: #ffffff;
      border: 3px solid var(--cream-color);
      border-radius: 50%;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      z-index: 1;
      cursor: pointer;
      margin: auto;
      flex: 0 0 24px;
    }

    input:hover,
    input:focus {
      border-color: var(--main-color);
    }

    input:checked {
      border-color: var(--main-color);
      background-color: var(--main-color);
      transition: all 0.2s ease-out;
    }
  }

  @media (max-width: 1285px) {
    &__answer {
      padding: 20px 20px;
    }

    &__answer {
      width: 100%;
    }
  }

  @media (max-width: 852px) {
    &__image {
      max-width: 90%;
    }

    &__description {
      font-size: 17px;
    }
  }

  @media (max-width: 560px) {
    &__radio-wrap {
      label {
        font-size: 16px;
      }
    }
  }
}
</style>