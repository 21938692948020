<template>
  <div class="final-page flex-1">
    <div class="final-page__content">
      <h1>Поздравляем, тест завершен!</h1>
      <div class="final-page__text">
        <p>Благодарим, что выбрали нас.</p>
        <p>
          Вы найдете результаты тестирования в своем личном кабинете. Если вы
          были приглашены к тестированию компанией, за результатами обратитесь к
          отправителю ссылки на прохождение теста.
        </p>
        <p>
          Вы сделали первый шаг на пути к тому, чтобы стать продвинутым
          пользователем эмоционального интеллекта. Не останавливайтесь на
          достигнутом – развивайте эмоциональный интеллект вместе с нами:
        </p>
        <div class="final-page__link-section">
          <p>Хотите узнать больше о работе эмоционального интеллекта?</p>
          Почитайте статьи в
          <a target="_blank" href="https://way2wei.ru/media-center">Медиацентре</a>
        </div>
        <div class="final-page__link-section">
          <p>Хотите определиться с решением для дальнейшего развития ЭИ?</p>
          Подберите решение в <a target="_blank" href="https://way2wei.ru/catalog">Каталоге</a>
        </div>
        <div class="final-page__link-section">
          <p>Хотите исследовать платформу WAY2WEI?</p>
          Ознакомьтесь с <a target="_blank" href="https://way2wei.ru/">Главной страницей</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "final-page",
};
</script>

<style scoped lang="scss">
.final-page {
  text-align: left;
  padding: 50px 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-word;

  &__content {
    margin: -5% auto 0;
    padding: 50px;

    h1 {
      font-size: 50px;
    }
    p {
      margin-bottom: 25px;
      font-size: 18px;
    }
  }

  &__link-section {
    font-size: 18px;
    p {
      margin-bottom: 6px;
    }
    a {
      color: var(--main-color);
    }
    margin-bottom: 25px;
  }

  @media (max-width: 1036px) {
    &__content {
      padding: 20px;
      h1 {
        font-size: 35px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
</style>