// Функция с общим для всех типов вопросов функционалом - запись ответа при нажатии на кнопку и очищение всех radio после перехода на следующий вопросо
// Требуется контекст компонента
export default function (context) {

    let answers = {}

    const setAnswer = (e) => {
        answers[e.currentTarget.dataset.question] =
            e.currentTarget.dataset.answer;
        context.emit("updateAnswers", answers);
    };

    const clearAnswers = () => {
        answers = {};
        let radios = document.querySelectorAll("input[type=radio]");
        if (radios) {
            radios.forEach((el) => (el.checked = false));
        }
    }

    return {
        setAnswer, clearAnswers
    }

}